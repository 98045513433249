import { CheckBox, Div, Text } from "../index"
import React from "react"
import { ICheckboxItem } from "./ICheckboxItem"
import styles from './CheckboxItem.module.css'

const CheckboxItem: React.FC<ICheckboxItem> = props => {
  const { title, active, onClick } = props

  return (
    <Div flex pointer onClick={onClick} className={active ? styles.activeWrapper : styles.wrapper}>
      <Div width={20} height={20}>
        <CheckBox active={active}/>
      </Div>
      <Text mLeft={16} size={16} text={title}/>
    </Div>
  )
}

export default CheckboxItem
