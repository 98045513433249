import React from "react"
import { IIcons } from "../IIcons"

export const ArrowMenuRightIcon: React.FC<IIcons> = props => {
  const { size = 24, color = '#737373'} = props
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 8L14 12L10 16" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
