import { ModalWrapper, Title, Button, Div, Text, InputField } from "../../../components"
import { useFormik } from "formik"
import { ILogIn } from "./ILogIn"
import { useNavigate } from "react-router-dom"
import { routes } from "../../../routes/const/routes"
import { SmallLogo } from "../../../icons"
import { validationSchemaLogin } from "../../../helpers/validationSchema"
import { useEffect, useState } from "react"
import { userLogin } from "../../../services/api/authAPI"
import { setToken } from "../../../store/reducer"
import { useDispatch } from "react-redux"
import helper from "../../../helpers/const"

export const LoginVal = {
  email: "",
  password: ""
}

const LogIn = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = localStorage.getItem(helper.JWT_TOKEN)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>("")

  useEffect(() => {
    if (token) {
      navigate('/documentChecklist')
    }
  },[token])

  const formik = useFormik<ILogIn>({
    initialValues: LoginVal,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema:  validationSchemaLogin,
    onSubmit: async (values) => {
      setLoading(true)
      setError('')
      try {
        const login = await userLogin({
          email: values.email,
          password: values.password
        })
        if (login.access_token) {
          dispatch(setToken(login.access_token))
        } else if (login?.response?.data?.detail?.length > 0) {
          setError(login?.response?.data?.detail)
        }
      } catch (e: any) {
        console.log('Error: ', e)
      } finally {
        setLoading(false)
      }
    }
  })

  const onForgotPassword = () => {
    navigate(routes.forgotPassword)
  }

  return (
    <Div flex height100 column>
      <Div mTop={46} flex jCenter>
        <SmallLogo/>
      </Div>
      <Div flex height100 centered jCenter mTop={-126}>
        <ModalWrapper width={500}>
          <Div flex jCenter mBottom={64}>
            <Title title={'Sing In'}/>
          </Div>
          <form onSubmit={formik.handleSubmit}>
            <InputField
              id={'email'}
              label={'EMAIL ADDRESS'}
              placeholder={"example@email.com"}
              value={formik.values.email}
              onChange={formik.handleChange("email")}
              error={formik.touched.email && formik.errors.email}
            />
            <InputField
              password
              id={'password'}
              label={'PASSWORD'}
              placeholder="Password"
              forgotPassword={onForgotPassword}
              value={formik.values.password}
              onChange={formik.handleChange("password")}
              error={formik.touched.password && formik.errors.password}
            />
            {error?.length > 0 && <Text text={error} size={15} color={'red'} mTop={8} mBottom={8}/>}
            <Button mTop={40} title={'CONTINUE'} loader={loading} arrowRight/>
          </form>
          <Text
            center
            mTop={40}
            size={14}
            color={'#00000066'}
            text={'By logging in, you agree to our Privacy Policy and Terms of Use'}
          />
        </ModalWrapper>
      </Div>
    </Div>
  )
}

export default LogIn
