import { Div, Text } from "../index"
import { ArrowMenuDownIcon, CrossIcon, FileIcon, FolderIcon } from "../../icons"
import { ArrowMenuRightIcon } from "../../icons/ArrowMenuRightIcon"
import React, { useState } from "react"
import { IFolderList } from "./IFolderList"

const FolderList: React.FC<IFolderList> = props => {
  const { data = [], onDelete } = props
  const [openedFolder, setOpenedFolder] = useState(false)

  console.log('data: ', data)

  const onOpenFolder = () => {
    setOpenedFolder(prev => !prev)
  }

  return (
    <>
      <Div flex centered height={48} pHorizontal={16} onClick={onOpenFolder} pointer>
        <Div flex row centered>
          {openedFolder ? <ArrowMenuDownIcon/> : <ArrowMenuRightIcon/>}
          <Div width={16}/>
          <FolderIcon/>
          <Text text={data?.category_name} size={14} width={'400'} mLeft={16}/>
        </Div>
      </Div>
      {data?.documents?.length > 0 && data?.documents?.map((e: any, i: number) => (
        <div key={e?.document_type?.id}>
          {openedFolder &&
            <Div flex row spaceBetween centered background={'#2A9D8F05'} height={48} radius={8} mBottom={2} key={i}>
              <Div flex row mLeft={56}>
                <FileIcon/>
                <Text text={e?.uploaded_document?.new_name} size={14} width={'400'} mLeft={16}/>
              </Div>
              <Div flex row mLeft={56}>
                <Text text={e?.document_type?.name} mTop={2} size={14} width={'600'} mLeft={16} color={'#737373'}/>
                <Div pHorizontal={16}>
                  <Div pointer onClick={() => onDelete?.(e?.uploaded_document?.id)}>
                    <CrossIcon color={'#737373'}/>
                  </Div>
                </Div>
              </Div>
            </Div>
          }
        </div>
      ))}
    </>
  )
}

export default FolderList
