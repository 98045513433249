import React from "react"
import { IIcons } from "../IIcons"

export const ArrowMenuDownIcon: React.FC<IIcons> = props => {
  const { size = 24, color = '#2C2C2C'} = props
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 10L12.0007 14L16 10" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
