import React from "react"
import { IIcons } from "../IIcons"

export const SingleFileIcon: React.FC<IIcons> = props => {
  const { color = '#737373', size = 24 } = props
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.9722 4.5V8.25C12.9722 8.76777 13.4075 9.1875 13.9445 9.1875H17.8334M13.139 4.5H8.11113C7.03724 4.5 6.16669 5.33947 6.16669 6.375V17.625C6.16669 18.6605 7.03724 19.5 8.11113 19.5H15.8889C16.9628 19.5 17.8334 18.6605 17.8334 17.625V9.02665C17.8334 8.52937 17.6285 8.05246 17.2638 7.70083L14.514 5.04917C14.1493 4.69754 13.6547 4.5 13.139 4.5Z" stroke={color} strokeWidth="2"/>
    </svg>
  )
}
