import { Div, FolderList, Text } from "../index"
import React from "react"
import { ISuccessfullyUploadedDocSection } from "./ISuccessfullyUploadedDocSection"

const SuccessfullyUploadedDocSection: React.FC<ISuccessfullyUploadedDocSection> = props => {
  const { data, filesNumber = 0, onDelete } = props

  return (
    <Div>
      <Text size={12} width={'500'} text={'SUCCESSFULLY UPLOADED DOCUMENTS'}/>
      <Div flex row centered mTop={8}>
        <Text size={14} color={'#737373'} text={`${filesNumber} documents`} mRight={5} />
        <Text size={14} color={'#737373'} text={'|'} />
        <Text size={14} color={'#737373'} text={`${data?.length} folders`} mLeft={5} />
      </Div>
      {data?.map((e: any, i: number) => (
        <FolderList data={e} key={i} onDelete={onDelete}/>
      ))}
    </Div>
  )
}

export default SuccessfullyUploadedDocSection
