import React from "react"
import { IIcons } from "../IIcons"

export const FileIcon: React.FC<IIcons> = props => {
  const { color = '#2A9D8F', size = 24 } = props
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5001 4.00034V7.00034C14.5001 7.55262 14.9478 8.00034 15.5001 8.00034H18.5001M9.00007 8.00034H11.0001M9.00007 11.0003H15.0001M9.00007 14.0003H15.0001M17.0001 5.50034C16.555 5.10212 16.0932 4.62982 15.8016 4.32307C15.6076 4.11895 15.3395 4.00034 15.0579 4.00034H7.49983C6.39527 4.00034 5.49984 4.89576 5.49983 6.00032L5.49976 18.0003C5.49975 19.1048 6.39518 20.0003 7.49975 20.0003L16.4998 20.0003C17.6043 20.0003 18.4998 19.1049 18.4998 18.0004L18.5001 7.39853C18.5001 7.14283 18.4025 6.89703 18.2251 6.71295C17.8969 6.37254 17.3489 5.81243 17.0001 5.50034Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
