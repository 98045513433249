import React, { memo, useMemo } from "react"
import { IButton } from "./IButton"
import styles from './Button.module.css'
import { Div } from "../index"
import { ArrowRightIcon } from "../../icons"
import PulseLoader from "react-spinners/PulseLoader";

const Button: React.FC<IButton> = props => {
  const { flex, title = '', disabled = false, deleteButton,  pHorizontal = 0, maxWidth, bordered = false, type= 'submit', mTop = 0, mBottom = 0, mLeft = 0, mRight = 0, arrowRight, width, loader = false, loaderColor = '#FFF', onClick } = props

  const buttonWrapperClassName = useMemo(() => {
    if (disabled) {
      return styles.wrapperDisabled
    } else if (bordered) {
      return styles.wrapperBordered;
    } else if (deleteButton) {
      return styles.wrapperDelete;
    } else {
      return styles.wrapper;
    }
  }, [bordered, deleteButton, disabled])

  return (
    <Div {...flex && { style: {flex: 1}}} mTop={mTop} mBottom={mBottom} mLeft={mLeft} mRight={mRight} {...maxWidth && {width: 'max-content'}} {...width && {width: width}}>
      <button
        type={type}
        disabled={disabled}
        className={buttonWrapperClassName}
        onClick={onClick}
        style={{...(pHorizontal && { paddingLeft: pHorizontal, paddingRight: pHorizontal })}}
      >
        {loader ?
          <PulseLoader
            color={loaderColor}
            loading={true}
            size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          :
          <>
            <span className={bordered ? styles.fontStyleBordered : styles.fontStyle}>
              {title}
            </span>
            {arrowRight &&
              <Div mLeft={16}>
                <ArrowRightIcon/>
              </Div>
            }
          </>
        }
      </button>
    </Div>
  )
}

export default memo(Button)
