import { Button, Div } from "../index"
import styles from './BottomBar.module.css'
import React from "react"
import { IBottomBar } from "./IBottomBar"

const BottomBar: React.FC<IBottomBar> = props => {
  const { onBackClick, onContinueClick, children, rightButtonText = 'CONTINUE', disabledRightButton = false, disabledLeftButton = false } = props
  return (
    <Div flex className={styles.wrapper} width100 centered height={101} background={'#FFFFFF'}>
      {!disabledLeftButton ?
        <Button
          bordered
          mLeft={80}
          mRight={25}
          width={320}
          title={'BACK'}
          onClick={onBackClick}
        />
        :
        <Div width={320} mRight={25}/>
      }
      <Div flex row centered jCenter width100>
        {children}
      </Div>
      <Button
        arrowRight
        mLeft={25}
        mRight={80}
        width={320}
        title={rightButtonText}
        onClick={onContinueClick}
        disabled={disabledRightButton}
      />
    </Div>
  )
}

export default BottomBar
