import React, { memo } from "react"
import { IText } from "./IText"
import { Div } from "../index"

const Text: React.FC<IText> = props => {
  const { text = '', className, mTop = 0, mBottom = 0, mLeft = 0, mRight = 0, size = 12, width = '500', color = '#000', center, alignCenter, underline } = props

  return (
    <Div mTop={mTop} mBottom={mBottom} mLeft={mLeft} mRight={mRight} {...center && {flex: true, jCenter: true}}>
      <span className={className} style={{fontSize: size, fontWeight: width, color: color,
        ...(alignCenter && { display: 'flex', textAlign: 'center' }),
        ...(underline && {textDecoration: 'underline'})
      }}>
        {text}
      </span>
    </Div>
  )
}

export default memo(Text)
