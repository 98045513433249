import React, { useState } from "react"
import { IInputField } from "./IInputField"
import styles from './InputField.module.css'
import { Div } from "../index"
import { EyeIcon } from "../../icons"

const InputField: React.FC<IInputField> = props => {
  const { id, label = '', placeholder = '', value, error = '', password, mTop = 0, mBottom = 0, onChange, forgotPassword } = props
  const [showPassword, setShowPassword] = useState(false)

  const onShowPassword = () => {
    setShowPassword(prev => !prev)
  }

  return (
    <Div mTop={mTop} mBottom={mBottom}>
      <Div className={styles.titleWrapper}>
        <span className={styles.title}>{label}</span>
        {forgotPassword &&
          <span onClick={forgotPassword} className={styles.forgotPassword}>
            FORGOT PASSWORD?
          </span>
        }
      </Div>
      <Div className={styles.inputWrapper}>
        <input
          value={value}
          className={styles.input}
          placeholder={placeholder}
          {...(password &&!showPassword) && {type: 'password'}}
          onChange={e => onChange(e.target.value)}
        />
        {password &&
          <Div pointer>
            <EyeIcon onClick={onShowPassword} state={!showPassword}/>
          </Div>
        }
      </Div>
      <Div className={styles.errorMessageWrapper}>
        <span className={styles.errorMessage}>{error}</span>
      </Div>
    </Div>
  )
}

export default InputField
