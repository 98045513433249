import { Div, DragAndDropComponent } from "../index"
import React, { useCallback } from "react"
import { IFileUploader } from "./IFileUploader"

const FileUploader: React.FC<IFileUploader> = props => {
  const { setSelectedFile } = props

  const handleFileInputChange = (files: any) => {
    if (files) setSelectedFile(files)
  }

  const handleClickUploadField = () => {
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.accept = '.pdf'
    fileInput.onchange = (event: any) => {
      const selectedFile = event.target.files
      if (selectedFile) handleFileInputChange(selectedFile)
    }
    fileInput.multiple = true
    fileInput.click()
  }

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      const selectedFile = event?.dataTransfer?.files
      selectedFile && handleFileInputChange(selectedFile)
    },
    [handleFileInputChange]
  )

  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }, [])

  return (
    <Div>
      <DragAndDropComponent
        handleDrop={handleDrop}
        handleDragOver={handleDragOver}
        handleClickUploadField={handleClickUploadField}
      />
    </Div>
  )
}

export default FileUploader
