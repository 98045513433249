import {
  BottomBar,
  Div,
  ModalWrapper,
  Title,
  SubTitle,
  CheckboxItem,
  Text,
  SelectedFromChecklists,
  SaveChecklist,
  EditChecklist,
  DeleteChecklist,
  BottomBarInfoItem
} from "../../../components"
import { DocumentTypes, SmallLogo } from "../../../icons"
import styles from './DocumentChecklist.module.css'
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { routes } from "../../../routes/const/routes"
import {
  deleteChecklists,
  getChecklists,
  getTemplateData,
  postChecklists, postSession
} from "../../../services/api/userAPI"
import Keys from "../../../helpers/const"
import helper from "../../../helpers/const"

const DocumentChecklist = () => {
  const token = localStorage.getItem(helper.JWT_TOKEN)
  const navigate = useNavigate()

  const [templateData, setTemplateData] = useState<Array<{ id: number, name: string, document_types: Array<{ id: number, name: string }> }>>([])
  const [selectedChecklistId, setSelectedChecklistId] = useState<Array<{ id: number, name: string }>>([])
  const [checkListName, setCheckListName] = useState('')
  const [checkListsData, setCheckListsData] = useState<any>([])

  const [editChecklist, setEditChecklist] = useState({id: null, document_types: [], name: ''})
  const [deleteChecklistId, setDeleteChecklistId] = useState<Number>()

  const [modalsState, setModalsState] = useState({
    saveModal: false,
    editModal: false,
    deleteModal: false,
  })
  const [modalErrors, setModalErrors] = useState({
    saveModal: '',
    editModal: '',
    deleteModal: '',
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // Save Modal
  const postChecklistReq = async () => {
    if (checkListName?.length <= 0) {
      setModalErrors(prev => ({...prev, saveModal: 'Checklist name is required'}))
      return
    }
    try {
      setModalErrors({ saveModal: '', editModal: '', deleteModal: '' })
      const res = await postChecklists({
        name: checkListName,
        saved: true,
        document_types: selectedChecklistId.map(item => item.id)
      })
      await getCheckListsData()
      await setSelectedChecklistId([])
      localStorage.setItem(Keys.CHECKLIST_ID, res.checklist_id)
      if (res.session_id) {
        localStorage.setItem(Keys.SESSION_ID, res.session_id)
        await navigate(routes.uploadDocuments)
      }
      await setModalsState(prev => ({...prev, saveModal: false}))
    } catch (error) {
      console.error("Error fetching account details:", error);
    }
  }

  const onShowSaveChecklist = () => {
    // navigate(routes.uploadDocuments)
    setModalsState(prev => ({...prev, saveModal: !prev.saveModal}))
  }
  //

  async function getCheckListsData () {
    try {
      const checklistsRes = await getChecklists()
      setCheckListsData(checklistsRes)
    } catch (error) {
      console.error("Error fetching account details:", error);
    }
  }

  const getTemplateDataReq = async () => {
    try {
      const res = await getTemplateData()
      setTemplateData(res)
    } catch (error) {
      console.error("Error fetching account details:", error);
    }
  }

  const onSelectedCheck = (item: any) => {
    setSelectedChecklistId((prev) => {
      const isValueInArray = prev.some((selectedItem) => selectedItem.id === item.id);

      if (isValueInArray) {
        return prev.filter((selectedItem) => selectedItem.id !== item.id);
      } else {
        return [...prev, item];
      }
    })
  }

  useEffect(() => {
    getTemplateDataReq?.()
    getCheckListsData?.()
  }, [])

  // Delete Modal
  const onDeleteClickItem = async () => {
    try {
      await deleteChecklists(deleteChecklistId?.toString())
      await getCheckListsData()
      await setModalsState(prev => ({...prev, deleteModal: false}))
    } catch (error) {
      console.error("Error fetching account details:", error)
    }
  }

  const onCancelDeleteItem = async () => {
    setModalsState(prev => ({...prev, deleteModal: false}))
  }
  //

  const onChecklistsEdit = async (data: any) => {
    await setEditChecklist(data)
    setModalsState(prev => ({...prev, editModal: true}))
  }

  const deleteChecklistReq = async (id: number) => {
    await setDeleteChecklistId(id)
    await setModalsState(prev => ({...prev, deleteModal: true}))
  }

  // Edit Modal
  const onEditSave = async () => {
    if (editChecklist?.name?.length <= 0) {
      setModalErrors(prev => ({...prev, editModal: 'Checklist name is required'}))
      return
    } else if (editChecklist.document_types.length <= 0) {
      setModalErrors(prev => ({...prev, editModal: 'Checklist can not be empty'}))
      return
    }
    try {
      setModalErrors({ saveModal: '', editModal: '', deleteModal: '' })
      await postChecklists({
        checklist_id: editChecklist.id,
        name: editChecklist.name,
        saved: true,
        // @ts-ignore
        document_types: editChecklist.document_types.map(item => item.id)
      })
      await getCheckListsData()
      await onEditCancel()
    } catch (error) {
      console.error("Error fetching account details:", error);
    }
  }

  const onDeleteItem = async (id: number) => {
    await setEditChecklist(prevData => ({
      ...prevData,
      // @ts-ignore
      document_types: prevData.document_types.filter(docType => docType?.id !== id)
    }))
  }

  const onChangeNameEditChecklist = (e: string) => {
    setEditChecklist(prev => ({...prev, name: e}))
  }

  const onEditCancel = () => {
    setModalsState(prev => ({...prev, editModal: false}))
  }
  //

  const onUseChecklist = async (id: number) => {
    try {
      const sessionId = await postSession({checklist_id: id})
      console.log('sessionId: ', sessionId)
      // const res = await getSession(sessionId.session_id)
      localStorage.setItem(Keys.SESSION_ID, sessionId.session_id)
      localStorage.setItem(Keys.CHECKLIST_ID, id.toString())
      if (sessionId.session_id) await navigate(routes.uploadDocuments)
    } catch (error) {
      console.error("Error fetching account details:", error);
    }
  }

  const onSkipChecklist = async () => {
    try {
      const res = await postChecklists({
        name: checkListName,
        saved: false,
        document_types: selectedChecklistId.map(item => item.id)
      })
      console.log('res: ', res)
      // localStorage.setItem(Keys.CHECKLIST_ID, res.checklist_id)
      localStorage.setItem(Keys.SESSION_ID, res.session_id)
      localStorage.setItem(Keys.CHECKLIST_ID, res.checklist_id)
      navigate(routes.uploadDocuments)
    } catch (error) {
      console.log('Error: ', error)
    }
  }

  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  },[token])

  return (
    <Div flex height100 column>
      <Div mTop={46} flex jCenter>
        <SmallLogo/>
      </Div>
      <Div flex jCenter mTop={48} pBottom={150}>
        <ModalWrapper width={1000}>
          <Div flex jCenter mBottom={64} column>
            <Title title={'Document Checklist'}/>
            <SubTitle alignCenter text={'Select documents you would like to upload.'}/>
          </Div>
          {checkListsData.length > 0 &&
            <Div>
              <Text text={'SELECT FROM YOUR CHECKLISTS'} mBottom={16}/>
              <Div className={styles.checklistWrapper} mBottom={24}>
                {checkListsData?.map((e: any, i: number) => (
                  <SelectedFromChecklists
                    key={e.id}
                    title={e.name}
                    onEditClick={() => onChecklistsEdit(e)}
                    onDeleteClick={() => deleteChecklistReq(e.id)}
                    onUseChecklist={() => onUseChecklist(e.id)}
                    documentTypes={e.document_types}
                    mBottom={checkListsData.length === i + 1 ? 0 : 16}
                  />
                ))}
              </Div>
              <Div height={1} background={'#D5D5D5'} mTop={24} mBottom={24}/>
            </Div>
          }
          {templateData?.length > 0 && templateData?.map(e => (
            <div key={e.id}>
              <Text mBottom={16} size={12} color={'#2C2C2C'} text={(e?.name?.toUpperCase()) + ':'}/>
              <Div className={styles.documentChecklistWrapper} width100 mBottom={24}>
                {e.document_types.map((docType) => (
                  <CheckboxItem
                    id={docType.id}
                    key={docType.id}
                    title={docType.name}
                    onClick={() => onSelectedCheck({ id: docType.id, name: docType.name })}
                    active={selectedChecklistId.some((item) => item.id === docType.id)}
                  />
                ))}
              </Div>
            </div>
          ))}
        </ModalWrapper>
      </Div>
      <BottomBar
        disabledLeftButton
        onContinueClick={onShowSaveChecklist}
        disabledRightButton={selectedChecklistId.length <= 0}
      >
        {selectedChecklistId.length > 0 && <BottomBarInfoItem icon={<DocumentTypes/>} number={selectedChecklistId.length} name={'Document types selected'} />}
      </BottomBar>
      <SaveChecklist
        value={checkListName}
        show={modalsState.saveModal}
        selectedItems={selectedChecklistId}
        onContinueClick={postChecklistReq}
        onSkipClick={onSkipChecklist}
        onBackClick={onShowSaveChecklist}
        onChangeValue={setCheckListName}
        error={modalErrors.saveModal}
      />
      <EditChecklist
        data={editChecklist}
        show={modalsState.editModal}
        onContinue={onEditSave}
        onDeleteItem={onDeleteItem}
        onCancel={onEditCancel}
        onNameChange={onChangeNameEditChecklist}
        error={modalErrors.editModal}
      />
      <DeleteChecklist
        show={modalsState.deleteModal}
        onDeleteClick={onDeleteClickItem}
        onCancelClick={onCancelDeleteItem}
      />
    </Div>
  )
}

export default DocumentChecklist
