import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import LogIn from "../pages/AuthPages/LogIn"
import { routes } from "./const/routes"
import ForgotPassword from "../pages/AuthPages/ForgotPassword"
import RecoveryLinkSent from "../pages/AuthPages/RecoveryLinkSent"
import ChangePassword from "../pages/AuthPages/ChangePassword"
import DocumentChecklist from "../pages/MainPages/DocumentChecklist"
import UploadDocuments from "../pages/MainPages/UploadDocuments"
import GeneratedList from "../pages/MainPages/GeneratedList"
import RouteWrapper from "../components/RouteWrapper"

const MainRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.login} element={<LogIn/>}/>
        <Route path={routes.forgotPassword} element={<ForgotPassword/>}/>
        <Route path={routes.recoveryLinkSent} element={<RecoveryLinkSent/>}/>
        <Route path={routes.changePassword} element={<ChangePassword/>}/>
        <Route path={routes.documentChecklist} element={<DocumentChecklist/>}/>
        <Route path={routes.uploadDocuments} element={<UploadDocuments/>}/>
        <Route path={routes.generatedList} element={<GeneratedList/>}/>
        <Route path={routes.home} element={<RouteWrapper/>}></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default MainRouter
