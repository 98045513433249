import React from "react"
import { IIcons } from "../IIcons"

export const EditIcon: React.FC<IIcons> = props => {
  const { size = 24, color = '#737373' } = props
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.4999 18.2928H18.4999M5.5 18.2928L9.13832 17.5597C9.33147 17.5208 9.50881 17.4257 9.6481 17.2864L17.7928 9.13716C18.1833 8.74645 18.1831 8.11312 17.7922 7.72274L16.0669 5.99934C15.6762 5.60912 15.0432 5.60938 14.6529 5.99994L6.5073 14.15C6.36829 14.2891 6.27337 14.4661 6.23442 14.6588L5.5 18.2928Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
