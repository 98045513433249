import { Button, Div, InputField, ListingItem, Modal, ModalWrapper, SubTitle, Title } from "../../index"
import React from "react"
import { ISaveChecklist } from "./ISaveChecklist"
import Text from "../../Text"

const SaveChecklist: React.FC<ISaveChecklist> = props => {
  const { value = '', selectedItems, show, error = '', onBackClick, onSkipClick, onContinueClick, onChangeValue } = props

  const handleCloseModal = () => {
    onBackClick()
  }

  return (
    <Modal show={show} onClose={handleCloseModal}>
      <ModalWrapper>
        <Title title={'Save checklist'}/>
        <SubTitle text={'To save the list of selected document types for future use, enter a name in the field below. Alternatively, you can skip this step.'}/>
        <InputField
          id={'id'}
          value={value}
          onChange={(e) => onChangeValue(e)}
          error={''}
          mTop={40}
          label={'CHECKLIST NAME'}
          mBottom={16}
        />
        {error?.length > 0 && <Text text={error} mBottom={12} color={'red'} size={15}/>}
        <div style={{ maxHeight: '200px', overflowY: 'scroll', paddingRight: 8 }}>
          {selectedItems.map(e => (
            <ListingItem text={e.name} key={e.id} mBottom={10}/>
          ))}
        </div>
        <Div flex row spaceBetween mTop={64}>
          <Div flex row>
            <Button bordered maxWidth pHorizontal={32} title={'BACK'} onClick={onBackClick}/>
            <Button mLeft={16}  mRight={16} bordered maxWidth pHorizontal={32} title={'SKIP'} onClick={onSkipClick}/>
          </Div>
          <Button arrowRight maxWidth pHorizontal={32} title={'SAVE & CONTINUE'} onClick={onContinueClick}/>
        </Div>
      </ModalWrapper>
    </Modal>
  )
}

export default SaveChecklist
