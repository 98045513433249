import React, { useReducer, useState } from 'react';
import ClickAwayListener from "react-click-away-listener"
import { Div, ListingItem, Text, UnderlineTextButton } from "../index"
import { DeleteIcon, DotsIcon, EditIcon } from "../../icons"
import { ISelectedFromChecklists } from "./ISelectedFromChecklists"
import UnderlineButton from '../UnderlineButton'
import styles from './SelectedFromChecklists.module.css'

const SelectedFromChecklists: React.FC<ISelectedFromChecklists> = props => {
  const {documentTypes, title = '', mBottom = 0, onUseChecklist, onEditClick, onDeleteClick} = props
  const [popup, setPopup] = useState(false)
  const [isShowMore, toggleIsFullList] = useReducer(state => !state, false)
  const documents = isShowMore ? documentTypes : documentTypes.slice(0, 4);

  return (
    <Div className={styles.wrapper} mBottom={mBottom}>
      <Div flex row spaceBetween mBottom={24}>
        <Div flex column>
          <Text size={18} text={title}/>
          <Div flex mTop={8}>
            <Text width={'bold'} text={documentTypes?.length?.toString()} color={'#737373'} size={16}/>
            <Text width={'400'} text={'Document types'} mLeft={6} color={'#737373'} size={16}/>
          </Div>
        </Div>
        <Div pointer className={styles.dotsWrapper} onClick={() => setPopup(true)}>
          <DotsIcon/>
          {popup && (
            <ClickAwayListener onClickAway={() => setPopup(false)}>
              <div className={styles.menuWrapper}>
                <div className={'popup'}>
                  <Div onClick={onEditClick} flex centered height={40} pHorizontal={12} className={styles.menuItemWrapper}>
                    <EditIcon/>
                    <Text size={14} width={'400'} mLeft={16} text={'Edit checklist'}/>
                  </Div>
                  <Div onClick={onDeleteClick} flex centered height={40} pHorizontal={12} className={styles.menuItemWrapper}>
                    <DeleteIcon/>
                    <Text size={14} width={'400'} mLeft={16} text={'Delete checklist'}/>
                  </Div>
                </div>
              </div>
            </ClickAwayListener>
          )}
        </Div>
      </Div>
      <Div style={{ marginBottom: 'auto' }}>
        {documents.map(e => (
          <ListingItem key={e.id} text={e.name} mBottom={10}/>
        ))}
        {documentTypes.length > 4 && (
          <UnderlineButton text={isShowMore ? "Show less" : "Show more"} onClick={toggleIsFullList}/>
        )}
      </Div>
      <Div flex jEnd mTop={24}>
        <UnderlineTextButton onClick={onUseChecklist}/>
      </Div>
    </Div>
  )
}

export default SelectedFromChecklists
