import React from "react"
import { IIcons } from "../IIcons"

export const UploadedIcon: React.FC<IIcons> = props => {
  const { size = 25, color = '#2A9D8F' } = props
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.7999 22.1H5.99984C4.67436 22.1 3.59984 21.0254 3.59985 19.7L3.59995 5.30001C3.59995 3.97453 4.67447 2.90002 5.99995 2.90002H16.8002C18.1257 2.90002 19.2002 3.97454 19.2002 5.30002V11.9M14.4002 18.7L16.6002 20.9L21.4002 16.0998M7.80023 7.70002H15.0002M7.80023 11.3H15.0002M7.80023 14.9H11.4002" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
