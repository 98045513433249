import { Div } from "../index"
import React from "react"
import { ICheckBox } from "./ICheckBox"
import styles from './CheckBox.module.css'
import { CheckIcon } from "../../icons"

const CheckBox: React.FC<ICheckBox> = props => {
  const { active } = props

  return (
    <Div flex pointer width={20} height={20} centered jCenter className={active ? styles.activeWrapper : styles.wrapper}>
      {active && <CheckIcon/>}
    </Div>
  )
}

export default CheckBox
