import React from "react"
import { IIcons } from "../IIcons"

export const SmallLogo: React.FC<IIcons> = props => {
  const { size = 80, color = '#2A9D8F', onClick } = props
  return (
    <svg onClick={onClick} width={size} height={size} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.0254 71.2228H65.8004V61.0512H36.0887V8.49866H25.0254V71.2228ZM31.2712 12.692V65.4237H62.2312V67.2078H29.4862V12.692H31.2712Z" fill={color}/>
    </svg>
  )
}
