import { Button, Div, InputField, ListingItem, Modal, ModalWrapper, Title } from "../../index"
import React from "react"
import { IEditChecklist } from "./IEditChecklist"
import Text from "../../Text"

const EditChecklist: React.FC<IEditChecklist> = props => {
  const { show, data, error, onNameChange, onCancel, onContinue, onDeleteItem } = props

  const handleCloseModal = () => {
    onCancel()
  }

  return (
    <Modal show={show} onClose={handleCloseModal}>
      <ModalWrapper>
        <Title title={'Edit checklist'}/>
        <InputField
          id={'id'}
          value={data.name}
          onChange={onNameChange}
          error={''}
          mTop={40}
          label={'CHECKLIST NAME'}
          placeholder={'Write checklist name'}
          mBottom={16}
        />
        {error?.length > 0 && <Text text={error} mBottom={12} color={'red'} size={15}/>}
        <div style={{ maxHeight: '200px', overflowY: 'scroll', paddingRight: 8 }}>
          {data?.document_types.length >0 && data?.document_types.map((e: any) => (
            <ListingItem key={e.id} text={e.name} mBottom={10} onDeleteClick={() => onDeleteItem(e.id)}/>
          ))}
        </div>
        <Div flex row mTop={64}>
          <Button mLeft={16}  mRight={16} bordered pHorizontal={32} title={'CANCEL'} onClick={onCancel}/>
          <Button flex arrowRight pHorizontal={32} title={'SAVE CHANGES'} onClick={onContinue}/>
        </Div>
      </ModalWrapper>
    </Modal>
  )
}

export default EditChecklist
