import React from "react"
import { IIcons } from "../IIcons"

export const DotsIcon: React.FC<IIcons> = props => {
  const { color = '#737373' } = props
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.1999 12C7.1999 13.3255 6.12539 14.4 4.7999 14.4C3.47442 14.4 2.3999 13.3255 2.3999 12C2.3999 10.6745 3.47442 9.59998 4.7999 9.59998C6.12539 9.59998 7.1999 10.6745 7.1999 12Z" fill={color}/>
      <path d="M14.3999 12C14.3999 13.3255 13.3254 14.4 11.9999 14.4C10.6744 14.4 9.5999 13.3255 9.5999 12C9.5999 10.6745 10.6744 9.59998 11.9999 9.59998C13.3254 9.59998 14.3999 10.6745 14.3999 12Z" fill={color}/>
      <path d="M21.5999 12C21.5999 13.3255 20.5254 14.4 19.1999 14.4C17.8744 14.4 16.7999 13.3255 16.7999 12C16.7999 10.6745 17.8744 9.59998 19.1999 9.59998C20.5254 9.59998 21.5999 10.6745 21.5999 12Z" fill={color}/>
    </svg>
  )
}
