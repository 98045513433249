import helper from "../../helpers/const"
import { Navigate } from "react-router-dom"

const RouteWrapper = () => {
  const token = localStorage.getItem(helper.JWT_TOKEN)

  if (token) {
    return <Navigate to={'/documentChecklist'}/>
  } else {
    return <Navigate to={'/login'}/>
  }
}

export default RouteWrapper
