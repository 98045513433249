import API from "../http"

const makeRequest = async (method: keyof typeof API, endpoint: string, data?: any) => {
  try {
    return await API[method](endpoint, data);
  } catch (error) {
    return error;
  }
}

export const getTemplateData = async () => makeRequest('get', '/template_data')
export const getChecklists = async () => makeRequest('get', '/checklists')
export const postChecklists = async (data: any) => makeRequest('post', '/checklist', data)
export const deleteChecklists = async (id: any) => makeRequest('delete', `/checklist?checklist_id=${id}`)
export const getSession = async (data: any) => makeRequest('get', `/session?session_id=${data}`, data)
export const postSession = async (data: any) => makeRequest('post', '/session', data)
export const postSessionUploadAllDuplicates = async (data: any) => makeRequest('post', '/session/upload_all_duplicates', data)
export const postSessionDownload = async (data: any) => makeRequest('get', `/session/download?session_id=${data.session_id}&zip_name=${data.zip_name}`)
export const deleteSessionUpload = async (data: any) => makeRequest('delete', `/session/upload?document_id=${data}`)
