import { Button, Div, ModalWrapper, Text, Title, InputField, SubTitle } from "../../../components"
import { ArrowLeftIcon, SmallLogo } from "../../../icons"
import { useNavigate } from "react-router-dom"
import { routes } from "../../../routes/const/routes"
import { useFormik } from "formik"
import { validationSchemaForgotPass } from "../../../helpers/validationSchema"
import { LoginVal } from "../LogIn"
import { IForgotPassword } from "./IForgotPassword"
import { useState } from "react"
import { postForgotPassword } from "../../../services/api/authAPI"
import { useDispatch } from "react-redux"
import { setRecoveryMail } from "../../../store/reducer"

export const ForgotPassVal = {
  email: "",
}

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const dispatch = useDispatch()

  const formik = useFormik<IForgotPassword>({
    initialValues: LoginVal,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema:  validationSchemaForgotPass,
    onSubmit: async (values) => {
      setError('')
      setLoading(true)
      try {
        const response = await postForgotPassword({ email: values.email })
        if (response.detail === 'success') {
          await dispatch(setRecoveryMail(values.email))
          navigate(routes.recoveryLinkSent)
        } else {
          setError(response.message)
        }
      } catch (error) {
        setError('Something went wrong')
      } finally {
        setLoading(false)
      }
    }
  })

  const onArrowBack = () => {
    navigate(routes.login)
  }

  return (
    <Div flex height100 column>
      <Div mTop={46} flex jCenter>
        <SmallLogo/>
      </Div>
      <Div flex height100 centered jCenter mTop={-126}>
        <ModalWrapper width={500}>
          <Div flex centered maxContent width100 pointer>
            <ArrowLeftIcon onClick={onArrowBack}/>
            <Div flex jCenter width100>
              <Title title={'Forgot Password'}/>
            </Div>
          </Div>
          <SubTitle alignCenter mBottom={64} text={'Enter the email to which the password recovery link will be sent'}/>
          <form onSubmit={formik.handleSubmit}>
            <InputField
              id={'email'}
              label={'EMAIL ADDRESS'}
              placeholder={"example@email.com"}
              value={formik.values.email}
              onChange={formik.handleChange("email")}
              error={formik.touched.email && formik.errors.email}
            />
            <Button loader={loading} mTop={40} arrowRight title={'SEND A LINK FOR RECOVERY'}/>
            <Text center alignCenter mTop={16} size={14} color={'red'} text={error}/>
          </form>
          <Text
            center
            mTop={40}
            size={14}
            color={'#00000066'}
            text={'By logging in, you agree to our Privacy Policy and Terms of Use'}
          />
        </ModalWrapper>
      </Div>
    </Div>
  )
}

export default ForgotPassword
