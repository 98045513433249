import React from "react"
import { IBottomBarInfoItem } from "./IBottomBarInfoItem"
import { Div } from "../index"
import Text from "../Text"

const BottomBarInfoItem: React.FC<IBottomBarInfoItem> = props => {
  const { icon, number, name, color = '#2C2C2C', leftLine, rightLine } = props
  return (
    <Div flex row centered>
      {leftLine && <Div height={24} width={1} background={'#D5D5D5'} mLeft={16} mRight={16}/>}
      {icon}
      <Text size={18} width={'800'} color={color} text={number.toString()} mLeft={10} mRight={10} />
      <Text size={18} width={'500'} color={color} text={name} />
      {rightLine && <Div height={24} width={1} background={'#D5D5D5'} mLeft={16} mRight={16}/>}
    </Div>
  )
}

export default BottomBarInfoItem
