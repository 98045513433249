import React from "react"
import { IIcons } from "../IIcons"

export const UploadingIcon: React.FC<IIcons> = props => {
  const { size = 25, color = '#737373' } = props
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.39286 15.9289C4.79518 15.9289 3.5 14.9291 3.5 13.4631C3.5 11.9972 4.79518 10.8088 6.39286 10.8088C6.5015 10.8088 6.60875 10.8143 6.71429 10.825V10.8088H6.75407C6.72781 10.6155 6.71429 10.4187 6.71429 10.2189C6.71429 7.61272 9.01682 5.5 11.8571 5.5C13.7805 5.5 15.4573 6.46881 16.3395 7.90361C16.5551 7.87452 16.7756 7.85945 17 7.85945C19.4853 7.85945 21.5 9.70808 21.5 11.9885C21.5 13.8657 20.1348 15.2967 18.2656 15.7684M12.358 19.5V12.7258M12.358 12.7258L9.40625 15.5196M12.358 12.7258L15.3125 15.5196" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
