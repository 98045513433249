import React from "react"
import { IModal } from "./IModal"
import ReactModal from "react-modal"

const Modal: React.FC<IModal> = props => {
  const { children, show = false, onClose } = props

  const customStyles = {
    content: {
      width: 'max-content',
      height: 'max-content',
      minWidth: 500,
      maxWidth: 640,
      borderRadius: 0,
      border: 0,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      overflow: 'auto',
    },
    overlay: {
      background: '#00000010',
    },
  };

  return (
    <ReactModal isOpen={show} style={customStyles} onRequestClose={onClose}>
      {children}
    </ReactModal>
  )
}

export default Modal
