import { Button, Div, Modal, ModalWrapper, SubTitle, Title } from "../../index"
import React from "react"
import { IDeleteChecklist } from "./IDeleteChecklist"

const DeleteChecklist: React.FC<IDeleteChecklist> = props => {
  const { show, onCancelClick, onDeleteClick } = props

  const handleCloseModal = () => {
    if (onCancelClick) {
      onCancelClick()
    }
  }

  return (
    <Modal show={show} onClose={handleCloseModal}>
      <ModalWrapper>
        <Title title={'Delete checklist'}/>
        <SubTitle text={'Are you sure you want to remove this checklist from your list?'}/>
        <Div mTop={64} flex row>
          <Button onClick={onCancelClick} flex mLeft={16}  mRight={16} bordered pHorizontal={32} title={'CANCEL'}/>
          <Button onClick={onDeleteClick} flex deleteButton pHorizontal={32} title={'DELETE'}/>
        </Div>
      </ModalWrapper>
    </Modal>
  )
}

export default DeleteChecklist
