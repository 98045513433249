import React from "react"
import { IIcons } from "../IIcons"

export const CheckIcon: React.FC<IIcons> = props => {
  const { color = '#FFFFFF' } = props
  return (
    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 1L3.03374 7L1 4.95476" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
