import React from "react"
import { IIcons } from "../IIcons"

export const FileUnidentifiedIcon: React.FC<IIcons> = props => {
  const { color = '#D1B44A', size = 24 } = props
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path d="M10.9998 20H7.49975C6.39518 19.9999 5.49975 19.1045 5.49976 17.9999L5.49983 5.99999C5.49984 4.89542 6.39527 4 7.49983 4H16.5001C17.6046 4 18.5001 4.89543 18.5001 6V12.5M18.5001 20L16.5001 18M16.5001 18L14.5001 16M16.5001 18L14.5001 20M16.5001 18L18.5001 16M9.00007 8H15.0001M9.00007 11H15.0001M9.00007 14H12.0001" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
