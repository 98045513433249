import React from "react"
import { IIcons } from "../IIcons"

export const FileMultipleIcons: React.FC<IIcons> = props => {
  const { color = '#737373', size = 32, stroke = 2 } = props
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.3602 8.31995V12.16C15.3602 12.8669 15.9333 13.44 16.6402 13.44H20.4802M23.0402 3.19995H14.0799C12.6661 3.19995 11.5199 4.34609 11.5199 5.75994V8.31995M23.0402 3.19995H23.7543C24.1147 3.19995 24.4579 3.35177 24.7062 3.61305C25.0794 4.00569 25.6705 4.61024 26.2402 5.11995C26.6867 5.51944 27.3881 6.23637 27.8082 6.67209C28.0354 6.90772 28.1602 7.22234 28.1602 7.54964L28.1602 8.31995M23.0402 3.19995V7.03995C23.0402 7.74688 23.6133 8.31995 24.3202 8.31995H28.1602M28.1602 8.31995L28.1599 21.12C28.1598 22.5338 27.0137 23.68 25.5999 23.6799H20.4798M18.5602 10.24C17.9905 9.73024 17.3994 9.12569 17.0262 8.73305C16.7779 8.47178 16.4347 8.31995 16.0743 8.31995H6.39991C4.98607 8.31995 3.83992 9.4661 3.83991 10.8799L3.83981 26.2399C3.8398 27.6537 4.98595 28.7999 6.3998 28.7999L17.9199 28.7999C19.3337 28.8 20.4798 27.6538 20.4799 26.24L20.4802 12.6696C20.4802 12.3423 20.3554 12.0277 20.1282 11.7921C19.7081 11.3564 19.0067 10.6394 18.5602 10.24Z" stroke={color} strokeWidth={stroke} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
