import React from "react"
import { IIcons } from "../IIcons"

export const DeleteIcon: React.FC<IIcons> = props => {
  const { size = 24, color = '#F04F4F' } = props
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.3335 7.14706H18.6668M9.50016 4.5H14.5002M10.3335 15.9706V10.6765M13.6668 15.9706V10.6765M14.9168 19.5H9.0835C8.16302 19.5 7.41683 18.7099 7.41683 17.7353L7.03633 8.06614C7.0166 7.56486 7.39509 7.14706 7.86894 7.14706H16.1314C16.6052 7.14706 16.9837 7.56486 16.964 8.06614L16.5835 17.7353C16.5835 18.7099 15.8373 19.5 14.9168 19.5Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
