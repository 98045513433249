import { Button, Div, ModalWrapper, Text, Title, SubTitle } from "../../../components"
import { SmallLogo } from "../../../icons"
import { useNavigate } from "react-router-dom"
import { routes } from "../../../routes/const/routes"
import { useSelector } from "react-redux"

const RecoveryLinkSent = () => {
  const navigate = useNavigate()
  const recoveryMail = useSelector((state: any) => state.user.recoveryMail)

  const onOkHandler = () => {
    navigate(routes.changePassword)
  }

  return (
    <Div flex height100 column>
      <Div mTop={46} flex jCenter>
        <SmallLogo/>
      </Div>
      <Div flex height100 centered jCenter mTop={-126}>
        <ModalWrapper width={500}>
          <Div flex jCenter>
            <Title title={'Recovery Link Sent'}/>
          </Div>
          <SubTitle alignCenter text={'A link to recover your password has been sent to your email:'}/>
          <Text
            center
            alignCenter
            size={20}
            width={'400'}
            color={'#2C2C2C'}
            mTop={16}
            mBottom={64}
            text={recoveryMail}
          />
          <Button onClick={onOkHandler} mTop={40} title={'OK'}/>
        </ModalWrapper>
      </Div>
    </Div>
  )
}

export default RecoveryLinkSent
