
export const routes = {
  // Auth
  home: '',
  login: '/login',
  forgotPassword:  `/forgotPassword`,
  registration: '/registration',
  recoveryLinkSent: '/recoveryLinkSent',
  changePassword: '/changePassword',

  // Main
  documentChecklist: '/documentChecklist',
  uploadDocuments: '/uploadDocuments',
  generatedList: '/generatedList',
}
