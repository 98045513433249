import { Div, Text } from "../index"
import { AlertIcon } from "../../icons"
import React from "react"
import { IMissingTypesSection } from "./IMissingTypesSection"

const MissingTypesSection: React.FC<IMissingTypesSection> = props => {
  const { data = [] } = props
  return (
    <Div>
      <Text size={12} width={'500'} text={'MISSING TYPES'}/>
      <Text size={14} color={'#737373'} text={`${data?.length} ${data?.length === 1 ? 'type' : 'types'}`} mTop={8} />
      <Div flex row mTop={24} wrap={'wrap'}>
        {data?.length > 0 && data?.map((e: any, i) => (
          <Div flex key={i} row centered maxContent pHorizontal={12} height={44} radius={8} background={'#FFE79029'} mRight={8} mBottom={8}>
            <AlertIcon/>
            <Text text={e?.name} mLeft={12} mTop={-5} width={'600'} size={14}/>
          </Div>
        ))}
      </Div>
    </Div>
  )
}

export default MissingTypesSection
