import { Div, Text } from "../index"
import { CrossIcon, SingleFileIcon } from "../../icons"
import React from "react"
import { IUploadingDocumentsSection } from "./IUploadingDocumentsSection"

const UploadingDocumentsSection: React.FC<IUploadingDocumentsSection> = props => {
  const { data } = props

  return (
    <Div>
      <Text text={'UPLOADING DOCUMENTS'}/>
      <Text text={`${data?.length} documents`} mTop={8} mBottom={24} color={'#737373'} size={14}/>
      {data?.map((e: {name: string, progress: string}, i: number) => (
        <Div key={i}>
          <Div flex row centered spaceBetween height={48}>
            <Div flex row centered>
              <SingleFileIcon/>
              <Text text={e?.name} mLeft={16} mTop={-4} size={14} width={'400'} color={'#737373'}/>
            </Div>
            <Div pointer>
              <CrossIcon color={'#737373'} size={20}/>
            </Div>
          </Div>
          <Div flex width={`${e?.progress}%`} mTop={-2} height={2} background={'#2A9D8F'}/>
        </Div>
      ))}
    </Div>
  )
}

export default UploadingDocumentsSection
