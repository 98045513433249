import React, { memo } from "react"
import { ITitle } from "./ITitle"

const Title: React.FC<ITitle> = props => {
  const { size = 32, color = '#000000', title, weight = '800' } = props
  return (
    <span style={{color: color, fontSize: size, fontWeight: weight}}>
      {title}
    </span>
  )
}

export default memo(Title)
