import * as Yup from "yup"
import { EMAIL_RE, PASSWORD_RE } from "./regEx"

export const validationSchemaLogin = Yup.object({
  email: Yup.string().matches(EMAIL_RE, "Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required")
})

export const validationSchemaForgotPass = Yup.object({
  email: Yup.string().matches(EMAIL_RE, "Invalid email").required("Email is required"),
})

export const validationSchemaResetPass = Yup.object({
  password: Yup.string()
    .matches(PASSWORD_RE, 'Password must contain at least 8 characters, one uppercase, one number and one special case character')
    .required('Password is required'),
  repeatPassword: Yup.string().required('Confirm Password is required').test(
    'repeatPassword',
    'Passwords must match',
    function(value) {
      return this.parent.password === value;
    }
  )
})
