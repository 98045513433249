import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Keys from '../helpers/const'

interface AuthState {
  token: string | null
  templateData: any
  forgotPasswordCode: string | null
  recoveryMail: string | null
}

const token = localStorage.getItem(Keys.JWT_TOKEN)

const initialState: AuthState = {
  token: token,
  templateData: 123,
  forgotPasswordCode: null,
  recoveryMail: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      localStorage.setItem(Keys.JWT_TOKEN, "Bearer " + action.payload)
      state.token = action.payload
    },
    setTemplateData: (state, action) => {
      state.templateData = action.payload
    },
    logoutFinish: (state) => {
      state.token = null
      localStorage.removeItem(Keys.JWT_TOKEN)
    },
    setRecoveryMail: (state, action) => {
      state.recoveryMail = action.payload
    }
  }
})

export const { setToken, setTemplateData, logoutFinish, setRecoveryMail } = authSlice.actions

export default authSlice.reducer
