import { Div, Text } from "../index"
import { ArrowRightIcon } from "../../icons"
import React from "react"
import { IUnderlineTextButton } from "./IUnderlineTextButton"

const UnderlineTextButton: React.FC<IUnderlineTextButton> = props => {
  const { onClick } = props
  return (
    <Div onClick={onClick} pointer flex row mTop={24} width={'max-content'}>
      <Text underline mRight={8} color={'#2A9D8F'} text={'USE THIS CHECKLIST'}/>
      <ArrowRightIcon color={'#2A9D8F'}/>
    </Div>
  )
}

export default UnderlineTextButton
