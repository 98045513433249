import { ComponentPropsWithoutRef } from 'react';
import { ArrowRightIcon } from "../../icons"
import styles from './UnderlineButton.module.css'

export type UnderlineButtonProps = {
  text: string
  hasArrow?: boolean
} &  ComponentPropsWithoutRef<'button'>

export default function UnderlineButton({ text, hasArrow, ...props }: UnderlineButtonProps) {
  return (
    <button className={styles.root} {...props}>
      {text}
      {hasArrow && <ArrowRightIcon color={'#2A9D8F'}/>}
    </button>
  )
}
