import { BottomBar, BottomBarInfoItem, Div, DocumentDuplicatesSection, FileUploader, MissingTypesSection, ModalWrapper, SubTitle, SuccessfullyUploadedDocSection, Text, Title, UploadingDocumentsSection, DocumentUnidentifiedSection } from "../../../components"
import { FileMultipleIcons, FileUnidentifiedIcon, SmallLogo, UploadedIcon, UploadingIcon } from "../../../icons"
import React, { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { routes } from "../../../routes/const/routes"
import Keys from "../../../helpers/const"
import { deleteSessionUpload, getSession, postSessionUploadAllDuplicates } from "../../../services/api/userAPI"
import axios from "axios"

const UploadDocuments = () => {
  const navigate = useNavigate()
  const session_id = localStorage.getItem(Keys.SESSION_ID)
  const [docData, setDocData] = useState<any>([])
  const [uploadProgress, setUploadProgress] = useState<Array<{ name: string, progress: number | string }>>([])
  const [fileUploadError, setFileUploadError] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const upload = (session_id: string, formData: FormData, onUploadProgress: (progress: number) => void) => {
    const config = {
      onUploadProgress: (progressEvent: any) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        onUploadProgress(percentCompleted);
      }
    }
    formData.append('session_id', session_id)
    return axios.post(process.env.REACT_APP_API_URL + '/session/upload', formData, config)
  }

  const refactoredDuplicatesData = useMemo(() => {
    return docData?.documents?.find((category: any) => category.category_name === "Duplicates")
      ?.documents?.reduce((acc: any, curr: any) => {
      if (curr.document_type === null) {
        return acc
      }

      const documentType = curr.document_type ? curr.document_type.name : 'unidentified'
      const existingEntry = acc.find((entry: any) => entry.document_type.name === documentType)

      if (existingEntry) {
        existingEntry.uploaded_document.push(curr.uploaded_document)
      } else {
        acc.push({
          document_type: { id: curr.document_type?.id, name: documentType },
          uploaded_document: [curr.uploaded_document],
        })
      }
      return acc
    }, [])
  }, [docData])

  const totalDuplicatesDataFiles = useMemo(() => refactoredDuplicatesData?.reduce((sum: any, e: any) => sum + (e.uploaded_document ? e.uploaded_document.length : 0), 0) || 0, [refactoredDuplicatesData])

  const filteredSuccessfullyUploadedDocData = useMemo(() => {
    return docData?.documents?.filter((item: any) => item.category_name !== 'Duplicates' && item.category_name !== 'Unidentified')
  }, [docData?.documents])

  const totalDocumentsByCategory = useMemo(() => {
    return filteredSuccessfullyUploadedDocData?.reduce((total: any, category: any) => {
      return total + (category.documents?.length || 0)
    }, 0)
  }, [filteredSuccessfullyUploadedDocData])

  const unidentifiedCategory = useMemo(() => {
    return docData?.documents?.find((category: any) => category.category_name === "Unidentified")
  }, [docData])

  const onUploadDocuments = async (files: File[]) => {
    if (!session_id) return

    const filesArray = Object.values(files)
    setFileUploadError('')

    const updatedUploadProgress = filesArray.reduce((acc, file) => {
      const existingFile = acc.find((uploadFile) => uploadFile.name === file.name)

      if (!existingFile) {
        acc.push({ name: file.name, progress: 0.5 })
      }

      return acc
    }, [...uploadProgress])

    setUploadProgress(updatedUploadProgress)

    for (const file of files) {
      const formData = new FormData()
      formData.append('file', file)

      try {
        const res = await upload(session_id, formData, (progress) => {
          setUploadProgress((prevState: any) =>
            prevState.map((item: any) =>
              item.name === file.name ? { ...item, progress } : item
            )
          )
          if (+progress === 100) {
            setUploadProgress((prevState: any) =>
              prevState.filter((item: any) => item.name !== file.name)
            )
          }
        })
        // console.log(`File: ${file.name} - Upload complete. Response:`, res)
        await onGetSessionReq()
      } catch (err) {
        // @ts-ignore
        setFileUploadError(err?.response?.data?.detail || '')
        setUploadProgress((prevState: any) =>
          prevState.filter((item: any) => item.name !== file.name)
        )
        // console.error(`File: ${file.name} - Upload failed. Error:`, err)
      }
    }
  }

  useEffect(() => {
    if (session_id) {
      onGetSessionReq()
    }
  },[session_id])

  const onGetSessionReq = async () => {
    try {
      const res = await getSession(session_id)
      setDocData(res)
    } catch (error) {
      console.error('Error fetching: ', error)
    }
  }

  const uploadAllDuplicates = async () => {
    await postSessionUploadAllDuplicates({session_id: session_id})
    onGetSessionReq()
  }

  const onDeleteDuplicate = async (id: number) => {
    try {
      await deleteSessionUpload(id)
      onGetSessionReq()
    } catch (error) {
      console.error('Error fetching: ', error)
    }
  }

  const uploadDoc = async (arr: Array<number>) => {
    for (const numberToDelete of arr) {
      await deleteSessionUpload(numberToDelete)
    }
    await onGetSessionReq()
  }

  const onContinueHandler = () => {
    navigate(routes.generatedList)
  }


  return (
    <Div flex height100 column>
      <Div mTop={46} flex jCenter>
        <SmallLogo/>
      </Div>
      <Div flex jCenter mTop={48} pBottom={150}>
        <ModalWrapper width={1000}>
          <Div flex jCenter mBottom={40} column>
            <Title title={'Upload Documents'}/>
            <SubTitle alignCenter text={'Select documents you would like to upload.'}/>
          </Div>
          <Div mBottom={24}>
            <Text text={'ATTACH ANY SUPPORTING DOCUMENTS'} mBottom={9}/>
            <Text text={'Accepted files: PDF. Max size: 10 MB.'} color={'#737373'}/>
          </Div>
          <FileUploader
            setSelectedFile={onUploadDocuments}
          />
          <Div height={18}/>
          {fileUploadError.length > 0 &&
            <Text text={fileUploadError} mBottom={16} color={'red'} size={16} />
          }
          {docData.missing_documents?.length > 0 &&
            <>
              <MissingTypesSection data={docData.missing_documents}/>
              <Div height={1} width100 background={'#D5D5D5'} mTop={24} mBottom={24}/>
            </>
          }
          {uploadProgress?.length > 0 &&
            <>
              <UploadingDocumentsSection data={uploadProgress} />
              <Div height={1} width100 background={'#D5D5D5'} mTop={24} mBottom={24}/>
            </>

          }
          {totalDuplicatesDataFiles > 0 &&
            <>
              <DocumentDuplicatesSection
                onUploadDoc={uploadDoc}
                onDelete={onDeleteDuplicate}
                onUploadAll={uploadAllDuplicates}
                data={refactoredDuplicatesData}
                totalNumber={totalDuplicatesDataFiles || 0}
              />
              <Div height={1} width100 background={'#D5D5D5'} mTop={24} mBottom={24}/>
            </>
          }
          {filteredSuccessfullyUploadedDocData?.length > 0 &&
            <>
              <SuccessfullyUploadedDocSection
                filesNumber={totalDocumentsByCategory}
                onDelete={onDeleteDuplicate}
                data={filteredSuccessfullyUploadedDocData}
              />
              <Div height={1} width100 background={'#D5D5D5'} mTop={24} mBottom={24}/>
            </>
          }
          {unidentifiedCategory?.documents?.length > 0 &&
            <DocumentUnidentifiedSection
              onRemove={uploadDoc}
              onUnidentifiedDelete={onDeleteDuplicate}
              data={unidentifiedCategory || {}}
            />
          }
        </ModalWrapper>
      </Div>
      <BottomBar onContinueClick={onContinueHandler} onBackClick={() => navigate(routes.documentChecklist)} disabledRightButton={filteredSuccessfullyUploadedDocData?.length <= 0 || unidentifiedCategory?.documents?.length > 0} rightButtonText={'DOWNLOAD ZIP & FINISH'}>
        {uploadProgress?.length > 0 && <BottomBarInfoItem icon={<UploadingIcon/>} number={uploadProgress?.length} name={'Uploading'} rightLine/>}
        {totalDuplicatesDataFiles > 0 && <BottomBarInfoItem icon={<FileMultipleIcons size={24} stroke={2.5}/>} number={totalDuplicatesDataFiles} name={'Duplicates'} rightLine/>}
        {totalDocumentsByCategory > 0 && <BottomBarInfoItem icon={<UploadedIcon/>} number={totalDocumentsByCategory} name={'Uploaded'} color={'#2A9D8F'}/>}
        {unidentifiedCategory?.documents?.length > 0 && <BottomBarInfoItem icon={<FileUnidentifiedIcon size={29} />} number={unidentifiedCategory?.documents?.length} name={'Unidentified'} color={'#CEAA42'} {...totalDocumentsByCategory > 0 && {leftLine: true}}/>}
      </BottomBar>
    </Div>
  )
}

export default UploadDocuments
