import React from "react"
import { IIcons } from "../IIcons"

export const ArrowRightIcon: React.FC<IIcons> = props => {
  const { size = 21, color = '#FFFFFF', onClick } = props
  return (
    <svg onClick={onClick} width={size} height={size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.3333 6.125L15.5 10.5M15.5 10.5L11.3333 14.875M15.5 10.5L5.5 10.5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
