import React from "react"
import { IIcons } from "../IIcons"

export const FolderIcon: React.FC<IIcons> = props => {
  const { color = '#737373', size = 24, stroke = 2 } = props
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.0008 9.01375L4.00073 15.284C4.00072 17.125 5.4931 18.6174 7.33406 18.6174L16.6664 18.6174C18.5073 18.6174 19.9997 17.1251 19.9997 15.2842L19.9999 9.51025C19.9999 8.58976 19.2537 7.84354 18.3332 7.84354H12.0697L9.76549 5.38217H5.66723C4.74651 5.38217 4.00022 6.12845 4.00047 7.04917L4.0008 9.01375Z" stroke={color} strokeWidth={stroke} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
