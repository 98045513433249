import React from "react"
import { IDragAndDropComponent } from "./IDragAndDropComponent"
import styles from './DragAndDropComponent.module.css'
import { FileMultipleIcons } from "../../icons"
import { Div, Text } from "../index"

const DragAndDropComponent: React.FC<IDragAndDropComponent> = props => {
  const { handleDrop, handleDragOver, handleClickUploadField } = props
  return (
    <div
      className={styles.wrapper}
      onClick={handleClickUploadField}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <FileMultipleIcons/>
      <Div flex row mTop={16}>
        <Text size={18} width={'400'} color={'#2A9D8F'} underline text={'Select all your files'} />
        <Text size={18} width={'400'} color={'#737373'} mLeft={5} text={'or drop them here'} />
      </Div>
    </div>
  )
}

export default DragAndDropComponent
