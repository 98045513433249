import React from "react"
import { IIcons } from "../IIcons"

export const AlertIcon: React.FC<IIcons> = props => {
  const { size = 24, color = '#D1B44A' } = props
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 11.9997V7.49966M12 15.3351V15.3747M21 11.9997C21 16.9702 16.9706 20.9997 12 20.9997C7.02944 20.9997 3 16.9702 3 11.9997C3 7.0291 7.02944 2.99966 12 2.99966C16.9706 2.99966 21 7.0291 21 11.9997Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
