import React from "react"
import { IIcons } from "../IIcons"

export const HoldingFolderIcon: React.FC<IIcons> = props => {
  const { size = 164 } = props
  return (
    <svg width={size} height={size} viewBox="0 0 164 164" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M109.863 100.573L73.2878 103.39L57.3203 115.044L53.2358 125.381H81.7515C83.1501 125.381 84.5351 125.103 85.8276 124.562L110.975 114.102L109.863 100.573Z" fill="#FFD2AF"/>
      <path d="M149.664 106.089H35.5885C35.3056 106.089 35.0258 106.029 34.767 105.913C34.5081 105.798 34.2758 105.629 34.0849 105.418C33.894 105.207 33.7487 104.957 33.6581 104.686C33.5675 104.415 33.5337 104.127 33.5588 103.842L40.9488 19.8075C40.9931 19.2934 41.226 18.8148 41.6017 18.466C41.9775 18.1172 42.4687 17.9236 42.9784 17.9235H157.972C158.257 17.9238 158.539 17.9846 158.799 18.1018C159.059 18.2191 159.293 18.3902 159.484 18.6042C159.675 18.8182 159.82 19.0703 159.909 19.3443C159.998 19.6184 160.03 19.9083 160.001 20.1954L151.693 104.23C151.642 104.739 151.407 105.211 151.032 105.554C150.657 105.898 150.169 106.088 149.664 106.089Z" fill="#2A9D8F"/>
      <path d="M157.47 27.0963H110.655C110.371 27.097 110.09 27.0376 109.83 26.9218C109.57 26.806 109.337 26.6365 109.146 26.4241C108.955 26.2117 108.81 25.9612 108.72 25.6886C108.63 25.4161 108.598 25.1275 108.625 24.8414L110.175 8.36697C110.223 7.85594 110.457 7.38147 110.833 7.03623C111.208 6.69099 111.697 6.4998 112.204 6.5H159.1C159.385 6.49977 159.667 6.55998 159.927 6.67673C160.187 6.79349 160.421 6.96421 160.612 7.17787C160.803 7.39153 160.948 7.6434 161.037 7.91722C161.126 8.19105 161.158 8.48075 161.13 8.76764L159.5 25.2464C159.448 25.7543 159.212 26.2247 158.837 26.5664C158.462 26.9081 157.975 27.0969 157.47 27.0963Z" fill="#2A9D8F"/>
      <path d="M37.4248 23.5158H151.087V106.089H37.4248V23.5158Z" fill="#72E0D3"/>
      <path d="M28.0811 31.8618H151.087V106.089H28.0811V31.8618Z" fill="#4EC2B4"/>
      <path d="M28.2954 106.089H149.411C149.704 106.088 149.992 106.024 150.258 105.9C150.524 105.777 150.76 105.597 150.952 105.373C151.143 105.15 151.284 104.887 151.367 104.603C151.449 104.319 151.47 104.02 151.428 103.727L143.802 50.3269C143.733 49.8358 143.49 49.3866 143.12 49.0617C142.749 48.7368 142.276 48.558 141.785 48.5579H19.7222C19.427 48.5583 19.1355 48.6237 18.8677 48.7494C18.5999 48.8751 18.3622 49.0582 18.1712 49.286C17.9802 49.5138 17.8404 49.7809 17.7614 50.0688C17.6824 50.3567 17.6661 50.6585 17.7137 50.9534L26.2869 104.354C26.363 104.838 26.6071 105.278 26.9752 105.596C27.3434 105.914 27.8115 106.089 28.2954 106.089Z" fill="#2A9D8F"/>
      <path d="M20.0213 54.5467H69.8606C70.1547 54.5472 70.4454 54.4832 70.7127 54.359C70.9799 54.2348 71.2174 54.0534 71.4088 53.8273C71.6001 53.6012 71.7407 53.3358 71.8209 53.0494C71.9011 52.7629 71.919 52.4623 71.8733 52.1682L70.4501 42.8462C70.3749 42.3601 70.1309 41.9171 69.762 41.5967C69.3931 41.2764 68.9234 41.0998 68.4373 41.0986H18.5265C18.2312 41.0991 17.9395 41.1644 17.6715 41.2901C17.4036 41.4158 17.1657 41.5988 16.9744 41.8265C16.7831 42.0542 16.6428 42.3212 16.5633 42.6091C16.4837 42.897 16.4668 43.1989 16.5137 43.4941L18.0085 52.8119C18.0864 53.2956 18.3316 53.7357 18.7002 54.0534C19.0689 54.3712 19.5371 54.546 20.0213 54.5467Z" fill="#2A9D8F"/>
      <path d="M4.20524 144.766L14.9891 124.767L14.7997 111.127C14.7784 109.556 15.0766 107.997 15.6757 106.547C16.2748 105.097 17.1619 103.788 18.282 102.7L28.9438 92.3378C31.4727 89.884 34.5927 88.1439 37.9929 87.291L68.6939 79.5717C73.01 78.5742 77.1998 81.5026 77.8525 85.586C78.4504 89.3157 75.9576 93.1349 72.0373 94.0598L50.1074 103.42L81.7601 107.069L112.621 105.73C116.411 105.066 119.569 107.435 119.935 109.903C120.335 112.602 117.518 116.118 113.253 116.003C73.9785 124.622 88.3753 121.544 50.5116 129.323L46.1029 154.728C45.9767 155.451 45.6311 156.117 45.1143 156.632C44.5974 157.148 43.9348 157.488 43.2185 157.605C29.5937 159.88 15.6238 156.833 4.13365 149.08C3.77919 148.843 3.48951 148.519 3.29142 148.139C3.09333 147.758 2.99322 147.333 3.00036 146.903C3.00749 146.473 3.12164 146.052 3.33224 145.678C3.54285 145.305 3.8431 144.991 4.20524 144.766Z" fill="#FFD2AF"/>
      <path d="M68.2016 122.145C68.1739 122.147 68.1462 122.143 68.12 122.134C68.0939 122.125 68.0697 122.111 68.049 122.092C68.0283 122.073 68.0114 122.051 67.9993 122.026C67.9872 122 67.9801 121.973 67.9784 121.945C67.9767 121.917 67.9806 121.889 67.9896 121.862C67.9986 121.836 68.0128 121.811 68.0311 121.791C68.0495 121.77 68.0718 121.752 68.0967 121.74C68.1216 121.728 68.1487 121.721 68.1763 121.719L112.348 115.977C112.375 115.974 112.404 115.976 112.43 115.983C112.457 115.991 112.483 116.004 112.504 116.021C112.526 116.039 112.545 116.06 112.558 116.085C112.572 116.11 112.58 116.137 112.584 116.165C112.587 116.192 112.585 116.22 112.578 116.247C112.571 116.274 112.558 116.299 112.542 116.321C112.525 116.342 112.504 116.361 112.48 116.374C112.456 116.388 112.43 116.396 112.403 116.399C67.7552 122.205 68.2437 122.145 68.2016 122.145Z" fill="#EBBA95"/>
    </svg>
  )
}
