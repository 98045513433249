import { Button, Div, ModalWrapper, Title, InputField, SubTitle, Text } from "../../../components"
import { SmallLogo } from "../../../icons"
import { useFormik } from "formik"
import { validationSchemaResetPass } from "../../../helpers/validationSchema"
import { IChangePassword } from "./IChangePassword"
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from "../../../routes/const/routes"
import { postSetPassword } from "../../../services/api/authAPI"
import { useMemo, useState } from 'react';

export const LoginVal = {
  password: "",
  repeatPassword: ""
}

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const ChangePassword = () => {
  const navigate = useNavigate()
  const query = useQuery()
  const [errorMessage, setErrorMessage] = useState('')
  const code = query.get("code");

  const formik = useFormik<IChangePassword>({
    initialValues: LoginVal,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema:  validationSchemaResetPass,
    onSubmit: async (values) => {
      if (values.password !== values.repeatPassword) {
        setErrorMessage('Passwords do not match')
        return
      } else {
        setErrorMessage('')
      }
      try {
        await postSetPassword({code, password: values.password})
        navigate(routes.login)
      } catch (e) {

      }
    }
  })

  return (
    <Div flex height100 column>
      <Div mTop={46} flex jCenter>
        <SmallLogo/>
      </Div>
      <Div flex height100 centered jCenter mTop={-126}>
        <ModalWrapper width={500}>
          <Div flex centered mBottom={64} column>
            <Title title={'Change Password'}/>
            <SubTitle alignCenter mTop={16} text={'Enter the new password in the fields below:'}/>
          </Div>
          <form onSubmit={formik.handleSubmit}>
            <InputField
              password
              id={'password'}
              label={'NEW PASSWORD'}
              placeholder="New password"
              value={formik.values.password}
              onChange={formik.handleChange("password")}
              error={formik.touched.password && formik.errors.password}
            />
            <InputField
              password
              id={'repeatPassword'}
              label={'REPEAT THE NEW PASSWORD'}
              placeholder="Repeat the new password"
              value={formik.values.repeatPassword}
              onChange={formik.handleChange("repeatPassword")}
              error={formik.touched.repeatPassword && formik.errors.repeatPassword}
            />
            {errorMessage?.length > 0 && <Text text={errorMessage} mTop={8} mBottom={8} color={'red'} size={15} />}
            <Button mTop={40} title={'SAVE NEW PASSWORD'}/>
          </form>
        </ModalWrapper>
      </Div>
    </Div>
  )
}

export default ChangePassword
