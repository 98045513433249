import API from "../http"

const makeRequest = async (method: keyof typeof API, endpoint: string, data?: any) => {
  try {
    return await API[method](endpoint, data)
  } catch (error) {
    return error
  }
}

export const userLogin = async (body: any) => {
  try {
    return await API.post("/login", body)
  } catch (error) {
    return error
  }
}

export const postForgotPassword = async (data: any) => makeRequest('post', '/forgot_password', data)
export const postSetPassword = async (data: any) => makeRequest('post', '/set_password', data)

