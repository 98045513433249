import { Button, Div, ModalWrapper, Text } from "../../../components"
import { FolderIcon, HoldingFolderIcon, SmallLogo } from "../../../icons"
import { routes } from "../../../routes/const/routes"
import { useNavigate } from "react-router-dom"
import Keys from "../../../helpers/const"
import { postSession, postSessionDownload } from "../../../services/api/userAPI"
import { useEffect, useState } from "react"
import styles from './GeneratedList.module.css'

const GeneratedList = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const session_id = localStorage.getItem(Keys.SESSION_ID)
  const checklist_id = localStorage.getItem(Keys.CHECKLIST_ID)
  const navigate = useNavigate()
  const [zipName, setZipName] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onDownload = async () => {
    setLoading(true)
    try {
      const res = await postSessionDownload({ session_id: session_id, zip_name: zipName?.length > 0 ? zipName : 'ZIP name' })
      if (zipName?.length <= 0) setZipName('ZIP name')
      await postSession({checklist_id: checklist_id})
      // Check if the response contains the URL
      if (res && res.url) {
        // Create a hidden link element
        const downloadLink = document.createElement('a');
        downloadLink.href = res.url;
        downloadLink.target = '_blank';
        downloadLink.download = 'your_file_name.zip'; // You can set the desired file name here

        // Append the link to the body
        document.body.appendChild(downloadLink);

        // Simulate a click on the link to trigger the download
        downloadLink.click();

        // Remove the link from the body
        document.body.removeChild(downloadLink);
      }
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      setLoading(false);
    }
  }

  const onLogOut = async () => {
    await localStorage.removeItem(Keys.JWT_TOKEN)
    navigate(routes.login)
  }

  return (
    <Div flex height100 column>
      <Div mTop={46} flex jCenter>
        <SmallLogo/>
      </Div>
      <Div flex jCenter mTop={48}>
        <ModalWrapper width={500}>
          <Div flex column centered>
            <HoldingFolderIcon/>
            <Text size={32} color={'#000000'} width={'800'} alignCenter mTop={48} text={'Your file list was \ngenerated successfully'} />
            <Text size={18} color={'#737373'} alignCenter mTop={16} text={'If the download did not automatically start, then use the button below'} />
            <Div width100>
              <Text className={styles.inputLabel} size={18} color={'#737373'} mTop={16} text="Zip name" />
              <Div className={styles.inputWrapper}>
                <FolderIcon size={32} stroke={1.5}/>
                <input
                  className={styles.input}
                  value={zipName}
                  placeholder="ZIP name"
                  onChange={e => setZipName(e.target.value)}
                />
              </Div>
            </Div>
            <Div flex width100 column>
              <Button
                flex
                mTop={32}
                loader={loading}
                title={'DOWNLOAD ZIP'}
                onClick={onDownload}
              />
              <Button
                flex
                bordered
                mTop={16}
                title={'START THE UPLOADING FILES AGAIN'}
                onClick={() => navigate(routes.documentChecklist)}
              />
            </Div>
          </Div>
        </ModalWrapper>
      </Div>
      <Div flex jCenter mTop={100} onClick={onLogOut}>
        <Div pointer mBottom={50}>
          <Text underline text={'LOG OUT'} color={'#F04F4F'} width={'500'} size={12}/>
        </Div>
      </Div>
    </Div>
  )
}

export default GeneratedList
