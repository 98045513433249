import React from 'react';
import ReactDOM from 'react-dom/client';
import './main.css';
import reportWebVitals from './reportWebVitals';
import MainRouter from './routes/MainRouter'
import { Provider } from "react-redux"
import { store } from "./store"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <MainRouter/>
  </Provider>
)

reportWebVitals();
