import { Text } from "../index"
import React from "react"
import { ISubTitle } from "./ISubTitle"

const SubTitle: React.FC<ISubTitle> = props => {
  const { size = 18, width = '400', text = '', color = '#737373', mTop = 16, mBottom = 0, alignCenter } = props

  return (
    <Text
      {...alignCenter && {alignCenter: true}}
      size={size}
      width={width}
      color={color}
      mTop={mTop}
      mBottom={mBottom}
      text={text}
    />
  )
}

export default SubTitle
