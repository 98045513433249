import { Div, Text } from "../index"
import { CrossIcon, FileMultipleIcons, InformationIcon } from "../../icons"
import { Tooltip } from 'react-tooltip'
import React from "react"
import { IDocumentDuplicatesSection } from "./IDocumentDuplicatesSection"

const DocumentDuplicatesSection: React.FC<IDocumentDuplicatesSection> = props => {
  const { data = {}, totalNumber = 0, onDelete, onUploadAll, onUploadDoc } = props
  const tooltipText = 'We identified duplicate files of the same type among the uploaded documents. You can opt to upload specific ones or choose to upload all of them based on your requirements.'

  const handleUploadDoc = (documentId: number) => {
    const currentDocumentType = data.find((entry: any) =>
      entry.uploaded_document.some((doc: any) => doc.id === documentId)
    )?.document_type.name;

    if (currentDocumentType) {
      const documentIds = data
        .filter((entry: any) => entry.document_type.name === currentDocumentType)
        .flatMap((entry: any) => entry.uploaded_document.map((doc: any) => doc.id))
        .filter((id: number) => id !== documentId);

      onUploadDoc(documentIds)
    }
  }

  return (
    <Div>
      <Div flex row spaceBetween centered>
        <Div flex row centered>
          <Text text={'DOCUMENT TYPE DUPLICATES'} mRight={8} mTop={-5}/>
          <a data-tooltip-id="my-tooltip" data-tooltip-content={tooltipText}>
            <InformationIcon/>
          </a>
          <Tooltip id="my-tooltip" style={{width: 306, borderRadius: 8}} place={'bottom'}/>
        </Div>
        <div onClick={onUploadAll}>
          <Div pointer>
            <Text underline size={12} text={'UPLOAD ALL'} color={'#2A9D8F'}/>
          </Div>
        </div>
      </Div>
      <Div flex row centered mTop={8}>
        <Text size={14} color={'#737373'} text={`${totalNumber} documents`} mRight={5} />
        <Text size={14} color={'#737373'} text={'|'} />
        <Text size={14} color={'#737373'} text={`${data?.length} types`} mLeft={5} />
      </Div>
      {data?.length > 0 && data?.map((e: any) => (
        <div key={e?.document_type?.id}>
          <Text text={e?.document_type?.name} size={14} width={'600'} mTop={24} mBottom={16}/>
          {e?.uploaded_document?.map((e: any) => (
            <Div flex centered key={e?.id} background={'#FCFCFC'} height={48} pHorizontal={16} spaceBetween radius={8} mBottom={2}>
              <Div flex row>
                <FileMultipleIcons size={20} stroke={3}/>
                <Text size={14} width={'400'} text={e?.default_name} mLeft={18}/>
              </Div>
              <Div flex row>
                <Div onClick={() => handleUploadDoc(e?.id)} flex centered background={'#2A9D8F'} pHorizontal={12} height={24} radius={4} mRight={16} pointer>
                  <Text text={'UPLOAD DOC'} mTop={-2} color={'#FFFFFF'} width={'700'}/>
                </Div>
                <Div pointer onClick={() => onDelete(e?.id)}>
                  <CrossIcon color={'#737373'}/>
                </Div>
              </Div>
            </Div>
          ))}
        </div>
      ))}
    </Div>
  )
}

export default DocumentDuplicatesSection
