import React from "react"
import { IIcons } from "../IIcons"

export const CrossIcon: React.FC<IIcons> = props => {
  const { size = 24, color = '#F04F4F' } = props
  return (
    <svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 8.5L8 16.5M16 16.5L8 8.5" stroke={color} strokeWidth="2" strokeLinecap="round"/>
    </svg>
  )
}
