import { Div, Text } from "../index"
import React from "react"
import { IListingItem } from "./IListingItem"
import { CrossIcon } from "../../icons"

const ListingItem: React.FC<IListingItem> = props => {
  const { text, mBottom = 0, fontSize = 16, onDeleteClick } = props
  return (
    <Div flex row centered jCenter mBottom={mBottom} spaceBetween>
      <Div flex row centered>
        <Div>
          <Div width={8} height={8} radius={8} background={'#D5D5D5'} mRight={16}/>
        </Div>
        <Text text={text} size={fontSize} width={'400'}/>
      </Div>
      {onDeleteClick &&
        <Div pointer mTop={3} onClick={onDeleteClick}>
          <CrossIcon/>
        </Div>
      }
    </Div>
  )
}

export default ListingItem
