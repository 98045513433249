import React from "react"
import { IModalWrapper } from "./IModalWrapper"
import styles from './ModalWrapper.module.css'
import { Div } from "../index"

const ModalWrapper: React.FC<IModalWrapper> = props => {
  const { children, width = 'auto' } = props
  return (
    <Div width={width} className={styles.wrapper}>
      {children}
    </Div>
  )
}

export default ModalWrapper
