import React from "react"
import { IDocumentUnidentifiedSection } from "./IDocumentUnidentifiedSection"
import { Div, Text } from "../index"
import { CrossIcon, FileUnidentifiedIcon } from "../../icons"

const DocumentUnidentifiedSection: React.FC<IDocumentUnidentifiedSection> = (props) => {
  const { data, onRemove, onUnidentifiedDelete } = props

  const onDeleteHandler = async () => {
    const idsArray = await data?.documents?.map((item: any) => item.uploaded_document.id)
    if (onRemove) {
      onRemove(idsArray)
    }
  }

  return (
    <>
      <Div flex spaceBetween>
        <Div>
          <Text text={'UNIDENTIFIED'} />
          <Text text={`${data?.documents?.length || 0} documents`} mTop={8} mBottom={24} color={'#737373'} size={14} />
        </Div>
        <Div>
          <Div onClick={onDeleteHandler} pointer>
            <Text underline size={12} text={'REMOVE ALL'} color={'#2A9D8F'}/>
          </Div>
        </Div>
      </Div>
      {data?.documents?.map((e: any) => (
        <Div flex centered background={'#FFE79014'} height={48} pHorizontal={16} spaceBetween radius={8} mBottom={2} key={e?.uploaded_document?.id}>
          <Div flex row>
            <FileUnidentifiedIcon />
            <Text size={14} width={'400'} text={e?.uploaded_document?.default_name} mLeft={18} />
          </Div>
          <Div pointer onClick={() => onUnidentifiedDelete(e?.uploaded_document?.id)}>
            <CrossIcon color={'#737373'} />
          </Div>
        </Div>
      ))}
    </>
  )
}

export default DocumentUnidentifiedSection;
