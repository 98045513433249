import React from "react"
import { IDiv } from "./IDiv"
import styles from './Div.module.css'
const Div: React.FC<IDiv> = props => {
  const { style, refer, size, height100, jEnd, width100, maxContent, row, mTop, mBottom, flex, fEnd, wrap, grow, radius, padding, pTop, pBottom, jCenter, mVertical, sAround, pVertical, sBetween, centered, background, mHorizontal, pHorizontal, spaceBetween, spaceAround, width, height, mLeft, mRight, className, children, pointer, column, onClick } = props

  const classNames = [
    flex && styles.flex,
    row && styles.row,
    jCenter && styles.jCenter,
    jEnd && styles.jEnd,
    sBetween && styles.sBetween,
    sAround && styles.sAround,
    fEnd && styles.fEnd,
    centered && styles.centered,
    className && className,
  ].filter(Boolean).join(' ');

  return (
    <div
      onClick={onClick}
      ref={(ref) => refer && refer(ref)}
      className={classNames}
      style={Object.assign(
        {},
        mLeft && { marginLeft: mLeft },
        mRight && { marginRight: mRight },
        width && {width: width},
        height && {height: height},
        pHorizontal && { paddingLeft: pHorizontal, paddingRight: pHorizontal },
        mVertical && { marginVertical: mVertical },
        mHorizontal && { marginHorizontal: mHorizontal },
        pVertical && { paddingVertical: pVertical },
        mTop && { marginTop: mTop },
        mBottom && { marginBottom: mBottom },
        pTop && { paddingTop: pTop },
        pBottom && { paddingBottom: pBottom },
        background && { backgroundColor: background },
        wrap && { flexWrap: "wrap" },
        grow && { flexGrow: 1 },
        radius && { borderRadius: radius },
        padding && { padding: padding },
        spaceBetween && { justifyContent: 'space-between' },
        spaceAround && { justifyContent: 'space-around' },
        pointer && { cursor: 'pointer' },
        column && { flexDirection: 'column' },
        maxContent && { width: 'max-content' },
        size && {width: size, height: size},
        height100 && {height: '100%'},
        width100 && {width: '100%'},
        style && style
      )}
    >
      {children}
    </div>
  )
}

export default Div
