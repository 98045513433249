import React from "react"
import { IIcons } from "../IIcons"

export const ArrowLeftIcon: React.FC<IIcons> = props => {
  const { size = 20, color = '#737373', onClick } = props
  return (
    <svg onClick={onClick} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.88954 15.8334L3.33398 10M3.33398 10L8.88954 4.16669M3.33398 10L16.6673 10" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
